<style>
	.suggest1 {  padding-bottom: 0 }
	.cartel_view1 { overflow: auto; padding-bottom: 10px }
	.swiper-wrpper1 { justify-content: flex-start }
	.swiper-slice1 { margin-right: 10px; flex-basis: 80px }
</style>
<template>
	<div
		v-if="false"
		class="section_wrap pt-50 pb-80"
	>
		<div class="suggest" style="padding-bottom: 0">
			<div class="container">
				<div class="row">
					<div class="suggest_tit">
						<h3>{{ $language.common.explain_cartel }}</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="small_thumbnail_wrap">
			<div
				class="container"
			>
				<div class="row">
					<ul class="small_thumbnail_list">
						<li>
							<div class="small_thumbnail_item">
								<a href="">
									<div class="thumbnail_area">
										<img src="/image/@noimage2.png" :alt="$language.cartel.recommend_cartel">
									</div>
								</a>
								<div class="text_area">
									<span class="name">{{ $language.cartel.WeAreCartel }}</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div
		v-else
		class="section_wrap pt-50 pb-80"
	>
		<!-- 추천 카르텔 -->
		<div class="suggest suggest1">
			<div class="container">
				<div class="row">
					<div class="suggest_tit">
						<h3>{{ $language.cartel.recommend_cartel }}</h3>
						<a
							v-if="items_cartel_recomm.length > 0"
							@click="$emit('to', { name: 'mafia046'})"
						><i class="icon-arrow-right"><span class="hide">{{ $language.cartel.recommendCartelBigView }}</span></i></a>
					</div>
				</div>
			</div>
			<div class="suggest_result">
				<div class="container">
					<div class="swiper cartel_view cartel_view1" >
						<template
							v-if="items_cartel_recomm.length > 0"
						>
							<div class="swiper-wrapper swiper-wrpper1">
								<div
									v-for="(item, index) in items_cartel_recomm"
									:key="'item_' + index"
									class="swiper-slide swiper-slice1"
								>
									<div class="cartel_item">
										<a @click="$emit('to', { name: 'mafia049', params: { idx: item.cartl_number, from: $route.name}})">
											<div class="picture">
												<img
													v-if="item.cartl_img_url"
													:src="$request.upload_url(item.cartl_img_url)"
												/>
												<img
													v-else
													:src="require('@/assets/image/@noimage.png')" :alt="$language.cartel.recommend_cartel"
												/>
											</div>
											<span class="name">{{  item.cartl_name }}</span>
										</a>
									</div>
								</div>
							</div>
						</template>
						<div
							v-else
							class="cartel_view_none"
						>
							<span class="cont_none">{{ $language.cartel.NoRecommendCartel }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- //추천 카르텔 -->
		<!-- 카르텔 썸네일 리스트 -->
		<div class="small_thumbnail_wrap">
			<div
				v-if="items_cartel.length > 0"
				class="container"
			>
				<div class="row">
					<ul class="small_thumbnail_list">
						<li
							v-for="(item, index) in list_my_cartel"
							:key="'item_' + index"
						>
							<div class="small_thumbnail_item">
								<a
									@click="$emit('to', { name: 'mafia049', params: {idx: item.cartl_number}})"
								>
									<div class="thumbnail_area">
										<img
											v-if="item.cartl_img_url"
											:src="$request.upload_url(item.cartl_img_url)" :alt="$language.cartel.recommend_cartel"
										>
										<img
											v-else
											:src="require('@/assets/image/@noimage2.png')" :alt="$language.cartel.recommend_cartel"
										>
									</div>
								</a>
								<div class="text_area">
									<span class="name">{{ item.cartl_name }}</span>
									<button
										v-if="item.cartl_entry_state_code"
										type="button"
										class="thumbnail_btn_s st_wjoin"
									>{{  item.cartl_entry_state_name }}</button>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div
				v-else
				class="small_thumbnail_flex"
			><!-- 해당 페이지 높이값 지정 -->
				<div class="none_top character3_none">
					<div class="text_guide" style="text-align: center">
						<span class="cont_none">{{ $language.cartel.recommend_cartel_message_01 }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //카르텔 썸네일 리스트 -->
		<div
			v-if="is_create"
			class="cartel_open"
		>
			<button
				@click="$emit('to', { name: 'mafia119'})"
			>
				<div class="cartel_open_btn">
					<span>{{ $language.common.cartel }}<br><em>{{ $language.common.establish }}</em></span>
				</div>
			</button>
		</div>
		
		<div
			v-if="is_event"
			class="box-event"
			style=""
		>
			<iframe
				:src="event_url"
				class="frame-event"
			></iframe>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'mafia044'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'cartel'
					, title: this.$language.common.cartel + ' ' + this.$language.common.list
					, type: 'cartel'
				}
				, items_cartel: []
				, items_cartel_recomm: []
				, cartl_open_possible_fg: ''
				, item_search: {
					page_number: this.$language.base.page_number
					, pagerecnum: this.$language.base.pagerecnum
				}
				, is_event: false
				, event_url: ''
			}
		}
		, computed: {
			is_create: function(){
				let t = false

				if(this.cartl_open_possible_fg == 'Y'){
					t = true
				}

				return t
			}
			, list_my_cartel: function(){

				return this.items_cartel.filter(function(item){
					if(item.status == 0){
						item.is_confirm = false
					}else{
						item.is_confirm = true
					}
					return item
				})
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_main_info
						, data: {
							member_number: this.user.member_number
							, cartl_list_page: {
								page_number: this.item_search.page_number
								, pagerecnum: this.item_search.pagerecnum
							}
							, nrt_post_list_page: {
								page_number: this.item_search.page_number
								, pagerecnum: this.item_search.pagerecnum
							}
						}
						, type: true
					})

					if(result.success){
						this.items_cartel = result.data.member_cartl_list
						this.items_cartel_recomm = result.data.recom_cartl_list
						this.cartl_open_possible_fg = result.data.cartl_open_possible_fg
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, postEvent: async function(){
				this.$bus.$emit('notify', { type: 'success', message: '투표가 완료되었습니다'})
			}

			, getEvent: async function(){
				this.is_event = true
				this.event_url = 'https://design.coinvest.kr/onus_voting/?a=' + (new Date())

				window.addEventListener('message', (e) => {
					//console.log(typeof e.data, e.data)
					if(typeof e.data === "string"){
						let d = JSON.parse(e.data)
						if(d.type == 'close'){
							if(confirm("이벤트 참여를 종료하시겠습니까?")){
								this.is_event = false
							}
						}else if(d.type == 'vote'){
							this.postEvent(d.data)
							this.is_event = false
						}
					}
				})
			}
		}
		, async created() {
			// console.log('044 user', this.user)
			this.$emit('onLoad', this.program)
			// await this.getEvent()
			await this.getData()

			if(await this.$common.getBrowser() === 'Safari'){
				document.body.scrollTop = 0
			}
		}
	}
</script>

<style>

	.box-event {
		position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color:  white; z-index: 9999999; overflow: hidden
	}

	.box-event::-webkit-scrollbar {
		width: 1px; color: black;
	}
	.frame-event {
		width: 100%; height: 100%; border: none; overflow: hidden;
	}
</style>